<template>
  <div class="flex justify-center w-screen h-screen">
    <div class="form-login">
      <div class="flex justify-center w-full text-3xl text-black mb-6">
        Sign In
      </div>
      <form>
        <AffInputGroup
          v-model="$v.formLogin.email.$model"
          label="Email"
          :vuelidate="$v.formLogin.email"
        />
        <AffInputGroup
          v-model="$v.formLogin.password.$model"
          label="Password"
          type="password"
          :vuelidate="$v.formLogin.password"
        />
        <AffButton
          text="Sign In"
          :loading="loading"
          class-name="bg-blue-500 text-white"
          @click.native.prevent="submitForm"
        />
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
export default {
  name: 'LoginPage',
  data () {
    return {
      loading: false,
      formLogin: {
        email: '',
        password: ''
      }
    }
  },
  validations: {
    formLogin: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }

  },
  methods: {
    ...mapActions('auth/account', ['login']),
    async submitForm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      }
      this.loading = true
      await this.login(this.formLogin)
      this.loading = false
    }
  }
}
</script>
